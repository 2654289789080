<template>
  <section>
    <NavBar />
    <Spinner v-if="isLoading" :color="'text-light'" />
   <div class="row justify-content-center">
     <div class="col-md-9">
        <div class="calltoaction container-fluid contest">
      <section class="row" v-if="!isLoading">
        <div class="col-sm-12 text-center">
          <div class="col-xs-12 col-sm-12 py-3">
            <div class="card">
              <div class="card-header">
                <h2>{{ vision.heading }}</h2>
              </div>
              <div class="card-body">
                <!-- <img
                  v-if="author"
                  :src="author.image"
                  class="img-fluid"
                  alt=""
                />
                <p v-if="author" class="blog-post-meta pb-4 pt-3">
                  {{ author.date && author.date | moment }} by
                  {{ author.author }}
                </p>
                <p v-if="!author" class="blog-post-meta pb-4">
                  {{ vision.created_at && vision.created_at | moment }} by
                  {{ vision.created_by && vision.created_by[0].first_name }}
                  {{ vision.created_by && vision.created_by[0].last_name }}
                </p> -->
                <!-- <img v-if="!author" class="img-thumbnail" :src="`${url()}/${vision.pic}`" alt=""> -->
                <!-- <img v-if="vision.pic" class="img-thumbnail" :src="vision.pic" alt=""> -->
                <p class="pt-4 text-justify">{{ vision.summary }}</p>
                <button
                  v-if="vision.thinkers_critique.length == 0"
                  pull-center
                  @click="addCritique(vision.id)"
                >
                  Add critique
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
     </div>
   </div>
    <Footer/>
  </section>
</template>

<script>
/* allow-no-unused-vars */
import DebateSidebar from "../debateSidebar";
import authors from "../../helpers/debates";
import challengeAuthors from "../../helpers/challengeAuthors";
import Spinner from "@/components/Spinner";
import moment from "moment";
import url from "../../helpers/url";
import NavBar from "../New/NavBar.vue";
import Footer from '../New/Footer.vue';

export default {
  components: {
    NavBar,
    Spinner,
    DebateSidebar,
    Footer,
  },

  filters: {
    moment: function (date) {
      return moment(date).format("MMMM Do YYYY");
    },
  },
  data() {
    return {
      vision: {},
      author: {},
      isLoading: false,
    };
  },
  methods: {
    url() {
      return url();
    },
    addCritique(challengeID) {
      this.$router.replace(`/challenge/${challengeID}/add_critique`);
    },
    fetchLeadersVision() {
      this.isLoading = true;
      this.$store
        .dispatch("fetchLeadersVision")
        .then((response) => {
          const data = response.data.find(
            (vision) => vision.id == this.$route.params.challengeID
          );
          if (!data) {
            this.$router.push({ name: "pageNotfound" });
          }
          this.vision = data;
          const author = challengeAuthors.find(
            (author) => author.id == data.id
          );
          this.author = author;
          this.isLoading = false;
        })
        .catch((err) => {
          //     if(err?.response?.data == undefined) {
          //     this.toast({
          //         message:'Network error. Check network connection and try again.',
          //         type:'error'
          //     })
          // }
        });
    },
  },
  created() {
    this.fetchLeadersVision();
  },
};
</script>

<style >
.contest {
  padding-left: 12%;
  padding-bottom: 7em;
  padding-right: 12%;
}

.calltoaction {
  padding-top: 150px;
  padding-left: 10px;
  padding-right: 10px;
}
.card-user {
  position: absolute;
  right: 10px;
  top: 10px;
}
.card-category {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 20px;
}
h2 {
  text-transform: uppercase;
}
.blog-post-meta {
  margin-bottom: 20px;
  color: #999;
}
.image {
  width: 100%;
  height: 500px;
}

button {
  background-color: #ba2428;
  color: #fff;
  border: 1px solid #ba2428;
  padding: 10px;
  transition: all 0.5s;
  outline: 0;
  font-size: 0.9em;
}
button:hover,
button:active {
  background-color: #ad5457;
  color: black;
}
</style>